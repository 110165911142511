import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
const FrontendLayout = () => import('../layouts/FrontendLayout')
const SubscribeLayout = () => import('../layouts/SubscribeLayout')

const LandingPage = () => import('../views/FrontendPages/MainPage.vue')
const MovieCatgeory = () => import('../views/FrontendPages/MovieCategory')
const MyVideos = () => import('../views/FrontendPages/MyVideos')
const MovieDetail = () => import('../views/FrontendPages/MovieDetail')

/* subscribe pages */
const Login = () => import('../views/SubscribePages/login.vue')
const Verify = () => import('../views/SubscribePages/verify.vue')

Vue.use(VueRouter)

const landingPageRoutes = (prop) => [{
  path: '/',
  name: prop + '.landing-page',
  meta: {
    auth: true,
    name: 'landing page 1',
    slider: 'true'
  },
  component: LandingPage
},
{
  path: '/category/:category',
  name: prop + '.category',
  meta: {
    auth: true,
    name: 'Movie Category',
    slider: 'false'
  },
  component: MovieCatgeory
},
{
  path: '/detail/:contentID',
  name: prop + '.detail',
  meta: {
    auth: true,
    name: 'Movie Detail',
    slider: 'false'
  },
  component: MovieDetail
},
{
  path: '/myVideos',
  name: prop + '.myVideos',
  meta: {
    auth: true,
    name: 'MyVideos',
    slider: 'false'
  },
  component: MyVideos
}
]

const subscribeRoutes = (prop) => [{
  path: '/',
  name: "/login page",
  meta: {
    auth: false,
    name: 'login',
    slider: 'true'
  },
  component: Login
},
{
  path: '/verify',
  name: "/verify page",
  meta: {
    auth: false,
    name: 'verify',
    slider: 'true'
  },
  component: Verify
}
]

const routes = [{
  path: '/login',
  name: 'Login',
  component: SubscribeLayout,
  meta: {
    auth: false
  },
  children: subscribeRoutes("susbcribe")
},
{
  path: '/',
  name: 'landing-page',
  component: FrontendLayout,
  meta: {
    auth: true
  },
  children: landingPageRoutes('landing-page')
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!localStorage.getItem('Laughter_Land')) {
      next("/login")
    } else {
      next()
    }
  } else {
    next()
  }
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
