import Vuex from 'vuex'
import Vue from 'vue'
import ApiService from './services/api'
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state () {
    return {
      phoneNumber: '',
      ti: '',
      ts: 123,
      points: 0,
      homePageCategory:[]
    }
  },
  getters:{
    getCategory(state){
      return state.homePageCategory;
    }
  },
  mutations: {
    setPhoneNumber (state, phone) {
      state.phoneNumber = phone
    },
    setTi (state, transcationID) {
      state.ti = transcationID
    },
    setTS (state, ts) {
      state.ts = ts
    },
    setPoints (state, points) {
      state.points = points
    },
    sethomePageCategory (state, data) {
      state.homePageCategory = data
    },
  },
   actions : {
    async fetchData({ commit },lang) {
      try {
        const response = await axios.get("https://laughter-land.tdzone.net:1337/api/v1/content/content-per-category?content_type= Image",{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("Laughter_Land"),
            lang: lang

          }
        });
        commit('sethomePageCategory', response.data.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  }
})
