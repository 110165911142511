<template>
  <router-view :style="{ 'font-family': fontByLanguage }"/>
</template>
<script>
import { core } from './config/pluginInit'

export default {
  name: 'App',
  components: {
  },
  mounted() {
    core.mainIndex()
    console.log(localStorage.getItem("Laughter_Land"))
  },
  computed: {
    fontByLanguage() {
      if (this.$i18n.locale === 'ku' || this.$i18n.locale === null) {
        return 'Speda, Arial, sans-serif';
      } else if (this.$i18n.locale === 'ar') {
        return 'Janna-LT, Arial, sans-serif';
      } else {
        return 'Roboto, sans-serif';
      }
    }
  },
}
</script>
<style lang="scss">
body{
  overflow-x: hidden !important;
  overflow-y:auto  !important;
}

:root {
  --plyr-color-main: #FED03A !important;
}



  @import "assets/scss/style.scss";

  @font-face {
	font-family: 'MyriadPro-Bold';
	src: url('./assets/fonts/MYRIADPRO-BOLD.OTF') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'Janna-LT';
  src: url('./assets/fonts/Janna-LT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Speda';
  src: url('./assets/fonts/Speda-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.text-left{
  text-align: left !important;
}
.text-right{
  text-align: right !important;
}

// change player color
// .plyr--full-ui.plyr--video .plyr__control--overlaid {
//   background: #FED03A !important;
// }

// .plyr--video .plyr__control:hover{
//   background: #FED03A !important;
// }

// .plyr--full-ui input[type=range] {
//   color: #FED03A !important;
// }

// .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
//   color: #FED03A !important;
// }

</style>
